import React, { useEffect, useState } from "react";
import {
  avatar,
  barMobile,
  barDesktop,
  button,
  buttonBlue,
  buttonOrange,
  cloud,
  container,
  barDesktopWrapper,
  buttonWhite,
  buttonsWrapper,
  icon,
} from "./question.module.scss";
import CheckIcon from "../../icons/CheckIcon";
import CloseIcon from "../../icons/CloseIcon";
import { StaticImage } from "gatsby-plugin-image";
import XIcon from "../../icons/XIcon";
import { motion, AnimatePresence } from "framer-motion";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import { fetchQuestions, getAvatar, getBackground } from "./helpers";
import HelpIcon from "../../icons/HelpIcon";
import HelpBar from "../HelpBar/HelpBar";

const Question = ({ increaseStep, increaseScore, step }) => {
  const [isUserStartedAudio, setIsUserStartedAudio] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [playing, setPlaying] = useState(false);

  const [list, setList] = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    fetchQuestions()
      .then((res) => {
        setList(res);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const activeQuestion = list[step];
  const activeAudio = list[step]?.mp3Url;
  const activeSex = list[step]?.sex;

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {loader && (
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Ładowanie...
          </div>
        )}

        {!loader && (
          <motion.div
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: 50 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
            layout
            key={step}
            className={container}
            style={{
              backgroundImage: `url(${getBackground(activeQuestion)})`,
            }}
          >
            <AudioPlayer
              isUserStartedAudio={isUserStartedAudio}
              setIsUserStartedAudio={setIsUserStartedAudio}
              playing={playing}
              setPlaying={setPlaying}
              audioUrl={activeAudio}
            />
            <div className={cloud}>{activeQuestion.question}</div>
            <img
              className={avatar}
              src={`${getAvatar(activeSex, activeQuestion)}`}
              alt="Avatar"
            />
            <div className={barMobile}>
              <button
                onClick={() => {
                  increaseStep();
                }}
                className={`${button} ${buttonOrange}`}
              >
                <CloseIcon />
                <span>Ominęło mnie</span>
              </button>
              <button
                onClick={() => {
                  increaseScore();
                  increaseStep();
                }}
                className={`${button} ${buttonBlue}`}
              >
                <CheckIcon />
                <span>Gdzieś to już słyszałem/am</span>
              </button>
            </div>
            <div className={barDesktop}>
              <div className={barDesktopWrapper}>
                <div>
                  <StaticImage
                    src="../../images/Logo-twojpsycholog.webp"
                    alt="Logo TwójPsycholog"
                  />
                  <div className={icon}>
                    <XIcon />
                  </div>
                  <StaticImage
                    src="../../images/Logo-the-village.webp"
                    alt="Logo The Village"
                  />
                </div>
                <div className={buttonsWrapper}>
                  <button
                    onClick={() => {
                      increaseStep();
                    }}
                    className={`${button} ${buttonOrange}`}
                  >
                    <CloseIcon />
                    <span>Ominęło mnie</span>
                  </button>
                  <button
                    onClick={() => {
                      increaseScore();
                      increaseStep();
                    }}
                    className={`${button} ${buttonBlue}`}
                  >
                    <CheckIcon />
                    <span>Gdzieś to już słyszałem/am</span>
                  </button>
                </div>
                <button
                  onClick={() => {
                    setShowHelp(true);
                  }}
                  className={`${button} ${buttonWhite}`}
                >
                  <HelpIcon />
                  <span>Pomoc</span>
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showHelp && <HelpBar setShowHelp={setShowHelp} />}
      </AnimatePresence>
    </>
  );
};

export default Question;
