import React from "react";
import { container, wrapper, logo, image } from "./landing.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import LandingBar from "../LandingBar/LandingBar";
import ArrowRightIcon from "../../icons/ArrowRightIcon";

const Landing = ({ setStep }) => {
  return (
    <>
      <section className={container}>
        <div className={wrapper}>
          <div className={logo}>
            <StaticImage
              src="../../images/Logo-nie-mow-mi-jak.webp"
              alt="Logo Nie mów mi jak"
            />
            <p>
              Ile z tych „dobrych rad” zdarzyło Ci się usłyszeć od osób z
              Twojego otoczenia? Rozpocznij test i sprawdź, jak bardzo masz
              przechlapane lub co jeszcze przed Tobą. Dowiesz się także, jak
              skutecznie reagować na krytykę innych oraz jak edukować, aby
              zachęcać do wyrozumiałości i empatii. Gra jest częścią kampanii
              społecznej <strong>#NieMówMiJak</strong>.
            </p>
            <button
              onClick={() => {
                setStep(1);
              }}
            >
              Start
              <ArrowRightIcon />
            </button>
          </div>
          <div className={image}>
            <StaticImage src="../../images/Landing.webp" alt="Landing page" />
          </div>
        </div>
      </section>
      <LandingBar />
    </>
  );
};

export default Landing;
