import React from "react";

const AudioOnIcon = () => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5305_8383)">
        <circle cx="75" cy="85" r="50" fill="white" />
      </g>
      <path
        d="M72.9165 70.4166L62.4998 78.75H54.1665V91.25H62.4998L72.9165 99.5833V70.4166Z"
        stroke="#0E1B48"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.7292 70.2709C93.6348 74.1777 95.8289 79.4758 95.8289 85C95.8289 90.5243 93.6348 95.8224 89.7292 99.7292M82.375 77.625C84.3278 79.5785 85.4249 82.2275 85.4249 84.9896C85.4249 87.7518 84.3278 90.4008 82.375 92.3542"
        stroke="#0E1B48"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_5305_8383"
          x="0"
          y="0"
          width="150"
          height="150"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-10" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.623529 0 0 0 0 0.643137 0 0 0 0 0.713726 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5305_8383"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5305_8383"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default AudioOnIcon;
