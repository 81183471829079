// extracted by mini-css-extract-plugin
export var audio = "question-module--audio--1z+c1";
export var avatar = "question-module--avatar--73kn0";
export var barDesktop = "question-module--barDesktop--C5uiP";
export var barDesktopWrapper = "question-module--barDesktopWrapper--gv8j5";
export var barMobile = "question-module--barMobile--JZNeg";
export var button = "question-module--button--GRHH4";
export var buttonBlue = "question-module--buttonBlue---Sjwc";
export var buttonOrange = "question-module--buttonOrange--kgDhs";
export var buttonWhite = "question-module--buttonWhite--T8S+D";
export var buttonsWrapper = "question-module--buttonsWrapper--g0pRw";
export var cloud = "question-module--cloud--Xoc9B";
export var container = "question-module--container--Z72qZ";
export var icon = "question-module--icon--I8dQw";