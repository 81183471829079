import React from "react";
import { container, wrapper, left, right } from "./bestCare.module.scss";
import ArrowRightIcon from "../../../../icons/ArrowRightIcon";

const BestCare = () => {
  return (
    <div className={container}>
      <div className={wrapper}>
        <div className={left}>
          <h3>Szukasz najlepszej opieki i edukacji dla Twojego dziecka?</h3>
          <p>
            Łączymy rodziców z twórcami wiosek - bliskościowych miejsc opieki i
            edukacji.
          </p>
          <p>
            Znajdź formę opieki skrojoną do potrzeb Twojej rodziny, w której dba
            się o potrzeby i zdrowy rozwój dzieci. Żłobki, przedszkola, domowa
            opieka łączona.
          </p>
          <a href="https://www.wioski.co/" target="_blank" rel="noreferrer">
            <button>
              Kliknij tutaj i znajdź swoją wioskę <ArrowRightIcon />
            </button>
          </a>
        </div>
        <div className={right}>
          <iframe
            title="youtube"
            src="https://www.youtube.com/embed/foo9x_S1UTo"
          />
        </div>
      </div>
    </div>
  );
};

export default BestCare;
