import emailMisspelled, { top100 } from "email-misspelled";

const expandedDomains = [
  ...top100,
  "gmail.com",
  "wp.pl",
  "wp.eu",
  "o2.pl",
  "interia.pl",
  "op.pl",
  "onet.pl",
  "poczta.onet.pl",
  "vp.pl",
  "tlen.pl",
  "onet.eu",
  "poczta.fm",
  "hotmail.com",
  "interia.eu",
  "gazeta.pl",
  "yahoo.com",
  "icloud.com",
  "buziaczek.pl",
  "outlook.com",
  "mail.ru",
  "yahoo.pl",
  "go2.pl",
  "spoko.pl",
  "yahoo.co.uk",
  "onet.com.pl",
  "poczta.onet.eu",
  "googlemail.com",
  "upcpoczta.pl",
  "vip.onet.pl",
  "opoczta.pl",
  "plusnet.pl",
  "poczta.pl",
  "aol.com",
  "live.com",
  "orange.pl",
  "hotmail.co.uk",
  "pro.onet.pl",
  "travelist.pl",
  "home.pl",
  "nazwa.pl",
];

export const emailDomainChecker = (email) => {
  const emailChecker = emailMisspelled({ domains: expandedDomains });
  const [firstItem] = emailChecker(email);

  if (firstItem) {
    return `Niepoprawna domena adresu email. Czy chodziło Ci o ${firstItem.suggest}?`;
  }
};
