import { endpoint } from "../../../config";
import background1 from "../../images/backgrounds/background1.webp";
import background2 from "../../images/backgrounds/background2.webp";
import background3 from "../../images/backgrounds/background3.webp";
import background4 from "../../images/backgrounds/background4.webp";
import background5 from "../../images/backgrounds/background5.webp";
import background6 from "../../images/backgrounds/background6.webp";
import femaleAvatar1 from "../../images/avatars/females/femaleAvatar1.webp";
import femaleAvatar2 from "../../images/avatars/females/femaleAvatar2.webp";
import femaleAvatar3 from "../../images/avatars/females/femaleAvatar3.webp";
import femaleAvatar4 from "../../images/avatars/females/femaleAvatar4.webp";
import femaleAvatar5 from "../../images/avatars/females/femaleAvatar5.webp";
import femaleAvatar6 from "../../images/avatars/females/femaleAvatar6.webp";
import maleAvatar1 from "../../images/avatars/men/maleAvatar1.webp";
import maleAvatar2 from "../../images/avatars/men/maleAvatar2.webp";
import maleAvatar3 from "../../images/avatars/men/maleAvatar3.webp";
import maleAvatar4 from "../../images/avatars/men/maleAvatar4.webp";

export const fetchQuestions = async () => {
  try {
    const res = await fetch(`${endpoint}/api-landing/open/village/game/start`);
    if (!res.ok) {
      throw new Error(`Response failed`);
    }
    const { questions } = await res.json();

    const generateRandomList = (length) => {
      const array = Array.from(Array(length + 1).keys())
        .splice(1)
        .sort(() => (Math.random() > 0.5 ? 1 : -1));

      return array.concat(array);
    };

    const background = generateRandomList(6);
    const maleAvatar = generateRandomList(4);
    const femaleAvatar = generateRandomList(6);

    return questions.reduce((acc, curr, index) => {
      const { question, mp3Url, sex } = curr;

      acc[index + 1] = {
        question,
        background: background[index],
        avatar: sex === "W" ? femaleAvatar[index] : maleAvatar[index],
        mp3Url,
        sex,
      };
      return acc;
    }, {});
  } catch (error) {
    console.log(error, "error");
  }
};

export const getBackground = (activeQuestion) => {
  switch (activeQuestion?.background) {
    case 1:
      return background1;
    case 2:
      return background2;
    case 3:
      return background3;
    case 4:
      return background4;
    case 5:
      return background5;
    case 6:
      return background6;
    default:
      return background1;
  }
};

export const getAvatar = (activeSex, activeQuestion) => {
  if (activeSex === "W") {
    switch (activeQuestion?.avatar) {
      case 1:
        return femaleAvatar1;
      case 2:
        return femaleAvatar2;
      case 3:
        return femaleAvatar3;
      case 4:
        return femaleAvatar4;
      case 5:
        return femaleAvatar5;
      case 6:
        return femaleAvatar6;
      default:
        return femaleAvatar1;
    }
  } else {
    switch (activeQuestion?.avatar) {
      case 1:
        return maleAvatar1;
      case 2:
        return maleAvatar2;
      case 3:
        return maleAvatar3;
      case 4:
        return maleAvatar4;
      default:
        return maleAvatar1;
    }
  }
};
