import React from "react";
import {
  checkboxContainer,
  checkboxError,
  checkboxLabel,
} from "./checkbox.module.scss";
import classNames from "classnames";

const Checkbox = ({ label, id, name, register, error, validation }) => {
  return (
    <div
      className={classNames(checkboxContainer, {
        [checkboxError]: error,
      })}
    >
      {register ? (
        <input
          id={id}
          name={name}
          type="checkbox"
          {...register(name, { ...validation })}
        />
      ) : (
        <input id={id} name={name} type="checkbox" />
      )}
      <label className={checkboxLabel} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
