import * as React from "react";
import { useState } from "react";
import Landing from "../components/Landing/Landing";
import "../components/reset.scss";
import Question from "../components/Question/Question";
import { Helmet } from "react-helmet";
import EndPage from "../components/EndPage/EndPage";

const IndexPage = () => {
  const [step, setStep] = useState(0);
  const [score, setScore] = useState(0);

  const increaseStep = () => {
    setStep(step + 1);
  };

  const increaseScore = () => {
    setScore(score + 1);
  };

  return (
    <>
      <Helmet>
        <html lang="pl" />
        <title>#NieMówMiJak - TwojPsycholog.pl & Wioski.co</title>
        <meta
          content="#NieMówMiJak - TwojPsycholog.pl & Wioski.co"
          property="og:title"
        />
        <meta
          content="#NieMówMiJak - TwojPsycholog.pl & Wioski.co"
          property="og:site_name"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Alata&family=Inter:wght@400;500;600&display=swap"
          rel="stylesheet"
        />
        {/*<meta content="" name="description" />*/}
        {/*<meta content="" name="og:description" />*/}
        {/*<meta content={getImg()} property="og:image" />*/}
        <meta name="viewport" content="width=device-width, minimum-scale=1.0" />
      </Helmet>
      {(() => {
        if (step === 0) {
          return <Landing setStep={setStep} />;
        }
        if (step === 11) {
          return <EndPage score={score} />;
        }
        return (
          <Question
            increaseStep={increaseStep}
            increaseScore={increaseScore}
            step={step}
          />
        );
      })()}
    </>
  );
};

export default IndexPage;
