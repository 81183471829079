import React, { useState } from "react";
import {
  tutorial,
  imageMobile,
  imageDesktop,
  wrapper,
  inputContainer,
} from "./tutorial.module.scss";
import { useForm } from "react-hook-form";
import { emailDomainChecker } from "../../../../utils/emailDomainChecker";
import { StaticImage } from "gatsby-plugin-image";
import Checkbox from "../../../Checkbox/Checkbox";
import { endpoint } from "../../../../../config";

const Tutorial = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    setLoader(true);
    try {
      const res = await fetch(
        `${endpoint}/api-landing/open/village/send-tutorials`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            type: "niemowmijak",
          }),
        }
      );

      if (res.ok) {
        setSuccess(true);
      }
    } catch (e) {
      console.log(e, "e");
      setError("Wystąpił błąd. Spróbuj jeszcze raz.");
      setSuccess(false);
    } finally {
      setLoader(true);
    }
  };

  return (
    <div className={tutorial}>
      <div className={imageMobile}>
        <StaticImage src="../../../../images/3_mobile.webp" alt="Obrazek" />
      </div>
      <div className={imageDesktop}>
        <StaticImage src="../../../../images/3_desktop.webp" alt="Obrazek" />
      </div>
      <div className={wrapper}>
        <h6>Pobierz bezpłatne poradniki</h6>
        <p>
          Zostaw nam swój e-mail i odbierz <strong>dwa bezpłatne</strong>
          przygotowane dla Ciebie poradniki, a także inne informacje
          przekazywane w ramach naszych newsletterów.
        </p>
        {(() => {
          if (success) {
            return (
              <p className={success}>
                Dziękujemy! Na maila <strong>{email}</strong> wysłaliśmy Ci
                nasze poradniki.
              </p>
            );
          }
          return (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={inputContainer}>
                <input
                  placeholder="E-mail"
                  {...register("email", {
                    required: "Email jest wymagany",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Niepoprawny adres email",
                    },
                    validate: (value) => emailDomainChecker(value),
                    onChange: ({ target: { value } }) => {
                      setEmail(value);
                    },
                  })}
                  type="text"
                />
                <button>{loader ? "Wysyłam..." : "Wyślij mi poradniki"}</button>
              </div>
              {error && <span>{error}</span>}
              {errors && <span>{errors.email?.message}</span>}
              <Checkbox
                id="checkbox"
                name="checkbox"
                validation={{
                  required: "Pole wymagane",
                }}
                error={errors?.checkbox?.message}
                register={register}
                label={
                  <>
                    Chcę otrzymywać newsletter – wiadomości o nowościach,
                    produktach i usługach związanych z działalnością
                    Współadministratorów.
                    <br />
                    <br /> W każdej chwili możesz zrezygnować z otrzymywania
                    newslettera. Twoje dane osobowe będą przetwarzane w celu
                    obsługi newslettera przez Współadministratorów. Więcej
                    informacji o przetwarzaniu danych osobowych znajdziesz w{" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://static.twojpsycholog.pl/download/niemowmijak-klauzula-informacyjna.pdf"
                    >
                      Klauzuli informacyjnej
                    </a>
                    .
                  </>
                }
              />
            </form>
          );
        })()}
      </div>
    </div>
  );
};

export default Tutorial;
