import React from "react";
import FirstSection from "./components/FirstSection/FirstSection";
import SecondSection from "./components/SecondSection/SecondSection";
import TherapistCarousel from "./components/TherapistCarousel/TherapistCarousel";
import BestCare from "./components/BestCare/BestCare";
import AboutAction from "./components/AboutAction/AboutAction";

const EndPage = ({ score }) => {
  return (
    <>
      <FirstSection score={score} />
      <SecondSection />
      <TherapistCarousel />
      <BestCare />
      <AboutAction />
    </>
  );
};

export default EndPage;
