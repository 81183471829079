import React, { useEffect, useState } from "react";
import {
  container,
  logo,
  opacity,
  wrapper,
  block,
  box,
  arrowsContainer,
  arrow,
  button,
} from "./therapistCarousel.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { StaticImage } from "gatsby-plugin-image";
import { endpoint } from "../../../../../config";

const TherapistCarousel = () => {
  const [swiper, updateSwiper] = useState(null);
  const [list, setList] = useState(null);

  const fetchTherapists = async () => {
    try {
      const res = await fetch(
        `${endpoint}/api-landing/open/village/specialists`
      );
      if (!res.ok) {
        throw new Error(`Response failed`);
      }
      const { propositions } = await res.json();
      setList(propositions);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchTherapists().catch((e) => {
      console.log(e, "e");
    });
  }, []);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  return (
    <section className={container}>
      <div className={wrapper}>
        <StaticImage
          className={logo}
          src="../../images/Logo-twojpsycholog.webp"
          alt="Logo TwójPsycholog"
        />
        <h2>Potrzebujesz wsparcia psychologicznego?</h2>
        <p>
          Trudne emocje towarzyszące byciu rodzicem to codzienność. Jeśli
          czujesz, że potrzebujesz wsparcia lub zauważasz u swojego dziecka
          niepokojące sygnały, możesz umówić wizytę do jednego z
          psychoterapeutów lub psychologów dziecięcych na portalu
          TwójPsycholog.pl
        </p>
        <div className={opacity} />
        <div className={block}>
          <Swiper
            slidesPerView="auto"
            spaceBetween={20}
            style={{ overflow: "inherit" }}
            onSwiper={updateSwiper}
          >
            {list?.map(
              ({
                avatarUrl,
                therapistId,
                fullName,
                description,
                sanitizeName,
              }) => (
                <SwiperSlide className={box} key={therapistId}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`https://twojpsycholog.pl/profil-psychologa/${sanitizeName}`}
                  >
                    <img src={avatarUrl} alt={fullName} />
                    <h4>{fullName}</h4>
                    <p>{description}</p>
                  </a>
                </SwiperSlide>
              )
            )}
          </Swiper>
          <div className={arrowsContainer}>
            <button className={arrow} onClick={goPrev}>
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 1L1 5L5 9"
                  stroke="#9FA4B6"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button className={arrow} onClick={goNext}>
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L1 9"
                  stroke="#9FA4B6"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className={button}>
          <a
            href="https://twojpsycholog.pl/lista-wszystkich-psychologow"
            rel="noreferrer"
            target="_blank"
          >
            <button>Pokaż wszystkich specjalistów</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default TherapistCarousel;
