import React from "react";

const HelpIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C12.1421 16 15.5 12.6421 15.5 8.5C15.5 4.35786 12.1421 1 8 1C3.85786 1 0.5 4.35786 0.5 8.5C0.5 12.6421 3.85786 16 8 16Z"
        stroke="#0E1B48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00293 13.5C8.55521 13.5 9.00293 13.0523 9.00293 12.5C9.00293 11.9477 8.55521 11.5 8.00293 11.5C7.45065 11.5 7.00293 11.9477 7.00293 12.5C7.00293 13.0523 7.45065 13.5 8.00293 13.5Z"
        stroke="#0E1B48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 4.07689C7.453 3.21689 9.518 3.23189 9.923 4.71189C10.453 6.63389 8 6.64189 8 9.49989"
        stroke="#0E1B48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HelpIcon;
