export const scoringDescriptions = (score) => {
  if (score <= 2) {
    return {
      title: "Odizolowana/y",
      description:
        "Albo nosisz ciągle słuchawki z redukcją hałasu, albo ukrywasz się przed innymi ludźmi. Inaczej jak udałoby Ci się uniknąć wysłuchania tylu „dobrych rad”? Cokolwiek robisz, rób tak dalej, bo widocznie działa. Z korzyścią dla Twojego zdrowia psychicznego! Jeśli jednak masz już dosyć chodzenia w słuchawkach lub chowania się w domu, możesz sięgnąć po nowe, prostsze sposoby.",
      button: "Chcę przestać się ukrywać",
    };
  }

  if (score > 2 && score <= 4) {
    return {
      title: "Świeżo upieczona/y",
      description:
        "Twoje dziecko prawdopodobnie urodziło się tydzień temu i w tym czasie zdążyłaś/eś spotkać tylko kilka „pomocnych” osób. Nie martw się, wszystko jeszcze przed Tobą. Tabun cioć, wujków i sąsiadów tylko czeka, żeby podzielić się informacją na temat wszystkiego, co robisz (według nich) źle. Inaczej skąd byś wiedział/a, że dziecko potrzebuje czapeczki, gdy jest 30°C, jak go nie przewiniesz w ciągu 0,0001 s, to odpadnie mu pupa, a nieprzypięte w wózku może porwać orzeł?",
      button: "Chcę być asertywny/a",
    };
  }

  if (score > 4 && score <= 6) {
    return {
      title: "Balansująca/y na granicy",
      description:
        "Gratulacje, dotarłaś/eś właśnie do rodzicielskiego rozdroża - przed Tobą dwie ścieżki. Każdy rodzic, który zderzył się z taką ilością krytyki, musi wybrać swoją przyszłość - „kompletna załamka” lub „mistrzostwo Zen”. Gdzie Ty chcesz się znaleźć?",
      button: "Chcę zostać Mistrzem Zen",
    };
  }

  if (score > 6 && score <= 8) {
    return {
      title: "Zaprawiona/y w bojach",
      description:
        "Jeszcze nie generał, ale już oficer. Niestety niechlubnej dywizji „Rodzice pod Ostrzałem”. Dezercja nie wchodzi już w grę, ale masz możliwość przenieść się do jednostki pancernej, bardziej odpornej na ostrzał. Co Ty na to?",
      button: "Chcę zmienić dywizję",
    };
  }

  return {
    title: "Weteran/ka",
    description:
      "Nie ma słów, które byłyby Ci obce. Krytykę i „dobre rady” odbijasz jak Kapitan Ameryka pociski. A może czujesz się raczej jak w Prison Break i marzysz o tym, aby w końcu uwolnić się od presji społecznej związanej z byciem nieidealnym rodzicem? Nic dziwnego, bo ileż można. Co powiesz na to, aby uzbroić się w nową broń - niezłomną asertywność i spokój ducha? Sprawdź jak.",
    button: "Chcę odnaleźć spokój ducha",
  };
};
