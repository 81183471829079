import React from "react";
import {
  container,
  button,
  rotateArrow,
  wrapper,
  top,
  icon,
} from "./helpBar.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import XIcon from "../../icons/XIcon";
import ArrowUpIcon from "../../icons/ArrowUpIcon";
import { motion } from "framer-motion";

const HelpBar = ({ setShowHelp }) => {
  return (
    <motion.div
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: "100%" }}
      exit={{ opacity: 0, y: "100%" }}
      transition={{ duration: 0.4 }}
      className={container}
    >
      <div className={wrapper}>
        <button
          onClick={() => {
            setShowHelp(false);
          }}
          className={button}
        >
          Zwiń
          <ArrowUpIcon className={rotateArrow} />
        </button>
        <div className={top}>
          <StaticImage
            src="../../images/Logo-twojpsycholog.webp"
            alt="Logo TwójPsycholog"
          />
          <div className={icon}>
            <XIcon />
          </div>
          <StaticImage
            src="../../images/Logo-the-village.webp"
            alt="Logo The Village"
          />
        </div>
        <h3>Instrukcja</h3>
        <p>
          Przeczytaj „dobre rady” na temat wychowania dzieci, które uwielbiają
          dawać ludzie w otoczeniu rodziców i zaznacz, czy otrzymałaś/eś kiedyś
          taką radę, czy miałaś/eś szczęście jej uniknąć.
        </p>
        <h3>O akcji</h3>
        <p>
          Bycie rodzicem to duże wyzwanie. Gdy do tego dołożyć krytykę ze strony
          bliskich i obcych osób, może robić się przytłaczające. Czy da się być
          perfekcyjnym rodzicem? Nie, i bardzo dobrze! Tą akcją chcemy pokazać,
          że najbardziej potrzebna jest przede wszystkim{" "}
          <strong>wyrozumiałość</strong>. Dla siebie i dla innych, którzy też
          uczą się, jak być dobrym (co nie znaczy perfekcyjnym) rodzicem. Brak
          tu miejsca dla „dobrych rad”, krytyki i szantażu emocjonalnego.{" "}
          <strong>
            Chcemy zrobić miejsce dla empatii, życzliwości i wsparcia
            społecznego.
          </strong>
        </p>
      </div>
    </motion.div>
  );
};

export default HelpBar;
