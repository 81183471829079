import React, { useEffect, useRef } from "react";
import { audio } from "../Question/question.module.scss";
import AudioOffIcon from "../../icons/AudioOffIcon";
import AudioOnIcon from "../../icons/AudioOnIcon";

const AudioPlayer = ({
  playing,
  setPlaying,
  audioUrl,
  isUserStartedAudio,
  setIsUserStartedAudio,
}) => {
  const audioRef = useRef();

  const play = () => {
    setPlaying(true);
    audioRef.current.play();
  };

  const pause = () => {
    setPlaying(false);
    setIsUserStartedAudio(false);
    audioRef.current.pause();
  };

  const handleOnClick = () => {
    if (playing) {
      pause();
    } else {
      play();
    }
  };

  useEffect(() => {
    audioRef.current.addEventListener("ended", () => {
      setPlaying(false);
      setIsUserStartedAudio(true);
    });

    return () => {
      setPlaying(false);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isUserStartedAudio) {
      play();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
      className={audio}
    >
      {/*eslint-disable-next-line*/}
      <audio ref={audioRef} src={audioUrl}>
        Twoja przeglądarka nie obsługuje elementu audio.
      </audio>
      {playing ? <AudioOnIcon /> : <AudioOffIcon />}
    </div>
  );
};

export default AudioPlayer;
