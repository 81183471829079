import React from "react";
import {
  container,
  block,
  logo,
  wrapper,
  first,
  second,
  arrow,
  image,
} from "./firstSection.module.scss";
import Logo from "../../../../icons/Logo";
import ArrowUpIcon from "../../../../icons/ArrowUpIcon";
import { StaticImage } from "gatsby-plugin-image";
import { scoringDescriptions } from "../../../../utils/scoringDescriptions";

const FirstSection = ({ score }) => {
  const { title, description, button } = scoringDescriptions(score);

  return (
    <div className={container}>
      <div className={logo}>
        <Logo />
      </div>
      <div className={block}>
        <div className={wrapper}>
          <div className={first}>
            <h3>Twój wynik</h3>
            <div>
              <span>{score}</span>/10
            </div>
          </div>
          <div className={second}>
            <h3>{title}</h3>
            <p>{description}</p>
            <a href="#scroll">
              <button>
                {button} <ArrowUpIcon className={arrow} />
              </button>
            </a>
          </div>
        </div>
        <div className={image}>
          <StaticImage
            src="../../../../images/endPageAvatar.webp"
            alt="Avatar"
          />
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
