import React from "react";
import { container, top, left, right } from "./secondSection.module.scss";
import FirstIcon from "../../icons/FirstIcon";
import SecondIcon from "../../icons/SecondIcon";
import Tutorial from "../Tutorial/Tutorial";

const SecondSection = () => {
  return (
    <>
      <div id="scroll" className={container}>
        <div className={top}>
          <h1>Jak radzić sobie z „dobrymi radami”?</h1>
          <p>
            Specjalnie dla Ciebie przygotowaliśmy{" "}
            <strong>dwa bezpłatne poradniki:</strong>
          </p>
        </div>
        <div className={left}>
          <FirstIcon />
          <span>Poradnik dla rodziców</span>
          <h3>Jak radzić sobie z krytyką otoczenia?</h3>
          <p>
            Poradnik o tym, jak być asertywnym, gdy otrzymujesz za dużo
            niechcianych rad i jak radzić sobie z trudnymi emocjami dotyczącymi
            bycia nieperfekcyjnym rodzicem (spoiler: nie ma takich!)
          </p>
        </div>
        <div className={right}>
          <SecondIcon />
          <span>Poradnik dla Twoich bliskich</span>
          <h3>Jak dawać rady tak, aby wspierać, a nie krzywdzić?</h3>
          <p>
            Poradnik, który możesz udostępnić wszystkim, których chcesz poprosić
            o bardziej empatyczne i wyrozumiałe podejście do Twojego
            rodzicielstwa.
          </p>
        </div>
      </div>
      <Tutorial />
    </>
  );
};

export default SecondSection;
