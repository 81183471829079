import React from "react";
import {
  container,
  wrapper,
  top,
  left,
  right,
} from "./aboutAction.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const AboutAction = () => {
  return (
    <div className={container}>
      <div className={wrapper}>
        <div className={top}>
          <h3>O akcji</h3>
          <p>
            Bycie rodzicem to duże wyzwanie. Gdy do tego dołożyć krytykę ze
            strony bliskich i obcych osób, może robić się przytłaczające.{" "}
            <strong>
              Czy da się być perfekcyjnym rodzicem? Nie, i bardzo dobrze!
            </strong>{" "}
            Tą akcją chcemy pokazać, że najbardziej potrzebna jest przede
            wszystkim <strong>wyrozumiałość</strong>. Dla siebie i dla innych,
            którzy też uczą się, jak być dobrym (co nie znaczy perfekcyjnym)
            rodzicem. Brak tu miejsca dla „dobrych rad”, krytyki i szantażu
            emocjonalnego.{" "}
            <strong>
              Chcemy zrobić miejsce dla empatii, życzliwości i wsparcia
              społecznego.
            </strong>
          </p>
        </div>
        <h4>Organizatorzy</h4>
        <div className={left}>
          <StaticImage
            src="../../images/Logo-twojpsycholog.webp"
            alt="Logo TwójPsycholog"
          />
          <p>
            TwojPsycholog.pl to platforma łącząca osoby poszukujące pomocy
            psychologicznej ze sprawdzonymi specjalistami zdrowia psychicznego.
            Portal umożliwia łatwe znalezienie i umówienie wizyty do jednego z
            1000 psychologów i psychoterapeutów z całej Polski i online (w tym
            ponad 200 psychologów dziecięcych).
            <br />
            Dzięki{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twojpsycholog.pl/dobor-specjalisty"
            >
              Formularzowi Doboru Specjalisty
            </a>{" "}
            każda osoba może otrzymać rekomendację specjalistów dopasowanych do
            swoich potrzeb i możliwości.
          </p>
          <a href="https://twojpsycholog.pl/" target="_blank" rel="noreferrer">
            <button>Znajdź wsparcie psychologiczne</button>
          </a>
        </div>
        <div className={right}>
          <StaticImage
            src="../../images/Logo-the-village.webp"
            alt="Logo The Village"
          />
          <p>
            W The Village działamy opierając się na szacunku do dziecka,
            rodzica, pedagogów. Wierzymy, że dzięki oddolnym, lokalnym
            inicjatywom jesteśmy w stanie zmienić edukację wczesnodzieciącą w
            Polsce i nie tylko, pozwalając rodzicom na spokojny powrót do pracy
            po urlopach rodzicielskich. Dlatego pomagamy otwierać wioski -
            żłobki, przedszkola, szkoły - oferując narzędzia, know-how, program
            i siłę społeczności. Wioski, w różnej formie, otwierają się w całej
            Polsce, tworząc nowe miejsca opieki i edukacji i wspierając rodziców
            w ich codziennych wyzwaniach.
          </p>
          <a href="https://www.wioski.co/" target="_blank" rel="noreferrer">
            <button>Poznaj Wioski</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutAction;
